// import dependencies
import React from "react";
import { Link, graphql } from "gatsby";
import { getMonthName } from "../utils/utilities";

// import components
import Layout from "../components/layout";
import PostNavButtons from "../components/posts-nav-buttons";
import Section from "../components/section";

// import styles and assets
import * as styles from "./styles/group-feed.module.scss";

export default function ArchivoPage({ data }) {
    return (
        <Layout>
            <Section>
                <PostNavButtons />
                <div className={styles.groupContainer}>
                    <h1>Archive</h1>
                    <h4>
                        Old and new, texts from around the times of writting
                        thing on the internet
                    </h4>
                    <div className={styles.groupBox}>
                        <h3>2024</h3>
                        <ul className={styles.groupFeed}>
                            {data.year2024Query.group.map((archivePosts) => (
                                <li
                                    className={styles.entryBox}
                                    key={archivePosts.fieldValue}
                                >
                                    <Link
                                        to={`/archive-post/2024/${archivePosts.fieldValue}/`}
                                    >
                                        {getMonthName(archivePosts.fieldValue)}
                                    </Link>
                                    <p>{archivePosts.totalCount} posts</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.groupBox}>
                        <h3>2023</h3>
                        <ul className={styles.groupFeed}>
                            {data.year2023Query.group.map((archivePosts) => (
                                <li
                                    className={styles.entryBox}
                                    key={archivePosts.fieldValue}
                                >
                                    <Link
                                        to={`/archive-post/2023/${archivePosts.fieldValue}/`}
                                    >
                                        {getMonthName(archivePosts.fieldValue)}
                                    </Link>
                                    <p>{archivePosts.totalCount} posts</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.groupBox}>
                        <h3>2022</h3>
                        <ul className={styles.groupFeed}>
                            {data.year2022Query.group.map((archivePosts) => (
                                <li
                                    className={styles.entryBox}
                                    key={archivePosts.fieldValue}
                                >
                                    <Link
                                        to={`/archive-post/2022/${archivePosts.fieldValue}/`}
                                    >
                                        {getMonthName(archivePosts.fieldValue)}
                                    </Link>
                                    <p>{archivePosts.totalCount} posts</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.groupBox}>
                        <h3>2021</h3>
                        <ul className={styles.groupFeed}>
                            {data.year2021Query.group.map((archivePosts) => (
                                <li
                                    className={styles.entryBox}
                                    key={archivePosts.fieldValue}
                                >
                                    <Link
                                        to={`/archive-post/2021/${archivePosts.fieldValue}/`}
                                    >
                                        {getMonthName(archivePosts.fieldValue)}
                                    </Link>
                                    <p>{archivePosts.totalCount} posts</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Section>
        </Layout>
    );
}

export const pageQuery = graphql`
    query {
        year2021Query: allMarkdownRemark(
            filter: { frontmatter: { year: { eq: 2021 } } }
            limit: 2000
        ) {
            group(field: frontmatter___month) {
                fieldValue
                totalCount
            }
        }
        year2022Query: allMarkdownRemark(
            filter: { frontmatter: { year: { eq: 2022 } } }
            limit: 2000
        ) {
            group(field: frontmatter___month) {
                fieldValue
                totalCount
            }
        }
        year2023Query: allMarkdownRemark(
            filter: { frontmatter: { year: { eq: 2023 } } }
            limit: 2000
        ) {
            group(field: frontmatter___month) {
                fieldValue
                totalCount
            }
        }
        year2024Query: allMarkdownRemark(
            filter: { frontmatter: { year: { eq: 2024 } } }
            limit: 2000
        ) {
            group(field: frontmatter___month) {
                fieldValue
                totalCount
            }
        }
    }
`;
